import * as React from 'react';
import {connect} from "react-redux";
import Loader from "react-loader-spinner";

function LoadingScreen(props) {

    return (
        <>
            {!props.isLoading ? null :
                <div style={{
                    cursor: 'progress',
                    background: '#282d38',
                    zIndex: 9999,
                    position: "absolute",
                    top: '0',
                    bottom: '0',
                    left: '0',
                    right: '0',
                    overflow: 'hidden'
                }}>
                    <div style={{position: "relative", top: '50%', left: '48%', transform: 'translate(0%,-50%)'}}>
                        <Loader
                            type="Bars"
                            color="#fff"
                            visible={true}
                        />
                    </div>
                </div>
            }
        </>
    );
};

export default connect(({ isLoading }) => ({ isLoading }))(LoadingScreen);
