/*!

=========================================================
* Argon Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useState} from "react";
// nodejs library that concatenates classes
import classnames from "classnames";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components
import AuthHeader from "components/Headers/AuthHeader.js";
import {login} from "../../../services/Redux/actions/auth";
import {connect} from "react-redux";
//import Swal from "sweetalert2";

export default connect(({ isLoading }) => ({ isLoading }), { login })(props => {
  const [focusedEmail, setfocusedEmail] = React.useState(false);
  const [focusedPassword, setfocusedPassword] = React.useState(false);

  const [email,setUser]= useState();
  const [password,setPin]= useState();
  const [error, setError] = useState("");

  const submitForm = () => {
    /*if (email === "" || password === "") {
      setError("Fields are required");
      return;
    }*/
    props.login({ email, password });
  };

  return (
      <>
        <AuthHeader
            title="Welcome!"
            lead="Use these awesome forms to login or create new account in your project for free."
        />
        <Container className="mt--8 pb-5">
          <Row className="justify-content-center">
            <Col lg="5" md="7">
              <Card className="bg-secondary border-0 mb-0">
                {/*<CardHeader className="bg-transparent pb-5">
                <div className="text-muted text-center mt-2 mb-3">
                  <small>Sign in with</small>
                </div>
                <div className="btn-wrapper text-center">
                  <Button
                    className="btn-neutral btn-icon"
                    color="default"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    <span className="btn-inner--icon mr-1">
                      <img
                        alt="..."
                        src={
                          require("assets/img/icons/common/github.svg").default
                        }
                      />
                    </span>
                    <span className="btn-inner--text">Github</span>
                  </Button>
                  <Button
                    className="btn-neutral btn-icon"
                    color="default"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    <span className="btn-inner--icon mr-1">
                      <img
                        alt="..."
                        src={
                          require("assets/img/icons/common/google.svg").default
                        }
                      />
                    </span>
                    <span className="btn-inner--text">Google</span>
                  </Button>
                </div>
              </CardHeader>*/}
                <CardBody className="px-lg-5 py-lg-5">
                  <div className="text-center text-muted mb-4">
                    <small>Hier muss ein Logo hin</small>
                  </div>
                  <Form role="form">
                    <FormGroup
                        className={classnames("mb-3", {
                          focused: focusedEmail,
                        })}
                    >
                      <InputGroup className="input-group-merge input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-email-83" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                            placeholder="Email"
                            type="email"
                            onFocus={() => setfocusedEmail(true)}
                            onBlur={() => setfocusedEmail(true)}
                            onChange={e=>setUser(e.target.value)}
                        />
                      </InputGroup>
                    </FormGroup>
                    <FormGroup
                        className={classnames({
                          focused: focusedPassword,
                        })}
                    >
                      <InputGroup className="input-group-merge input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-lock-circle-open" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                            placeholder="Passwort"
                            type="password"
                            onFocus={() => setfocusedPassword(true)}
                            onBlur={() => setfocusedPassword(true)}
                            onChange={e => setPin(e.target.value)}
                        />
                      </InputGroup>
                    </FormGroup>
                    <div className="custom-control custom-control-alternative custom-checkbox">
                      <input
                          className="custom-control-input"
                          id=" customCheckLogin"
                          type="checkbox"
                      />
                      <label
                          className="custom-control-label"
                          htmlFor=" customCheckLogin"
                      >
                        <span className="text-muted">Merken?</span>
                      </label>
                    </div>
                    <div className="text-center">
                      <Button className="my-4" color="info" type="button" onClick={submitForm}>
                        Einloggen
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </Card>
              <Row className="mt-3">
                <Col xs="6">
                  <a
                      className="text-light"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                  >
                    <small>Passwort vergessen?</small>
                  </a>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </>
  );
});
