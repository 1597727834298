import {applyMiddleware, compose, createStore} from "redux";
import {appMiddleware} from "./appMiddleware";
import {apiMiddleware} from "./apiMiddleware";
import reducer from "./reducer";

const composeEnhancers =
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        }) : compose;

const enhancer = composeEnhancers(
    applyMiddleware(
        appMiddleware,
        apiMiddleware,
    ),
);
export const store = createStore(reducer, enhancer);
