export const API_REQUEST = "API_REQUEST";
export const GETDASHBOARDDATA = "GETDASHBOARDDATA";
export const API_SUCCESS = "API_SUCCESS";
export const GETROUTESUCCESS = "GETROUTESUCCESS";
export const API_ERROR = "API_ERROR";
export const CANCEL_API_REQUEST = "CANCEL_API_REQUEST";
export const SETINFORMATIONDATA = "SET_INFORMATION_DATA";
export const API_CREATECUSTOMER = "API_CREATE_CUSTOMER";
export const API_CREATECUSTOMER_QS = "API_CREATE_CUSTOMER_QS";

// action creators
export const apiRequest = ({ url, method,data }) => {
    return {
        type: API_REQUEST,
        meta: { url, method,data }
    };
};

export const cancelApiRequest = () => {
    return {
        type: CANCEL_API_REQUEST
    };
};

export const apiSuccess = ({ response }) => ({
    type: API_SUCCESS,
    payload: response
});

export const apiError = ({ error }) => ({
    type: API_ERROR,
    payload: error
});

export const apiGetDashboardData = (url) => {
    return {
        type: GETDASHBOARDDATA,
        meta: url
    };
};

export const apiSaveInformation = (url,method,data) => {
    return {
        type: SETINFORMATIONDATA,
        meta: url
    };
};

export const apiCreateCustomer = ({url,method,data}) => {
    return {
        type: API_CREATECUSTOMER,
        meta: {url,method,data}
    };
};

export const apiCreateCustomerQS = ({method,data}) => {
    const BASE_URL = `https://api.quicksteps.ch`;
    return {
        type: API_CREATECUSTOMER_QS,
        meta: {method,data},
        metaurl: BASE_URL+'/qsapi/reseller/createInstance'
    };
};

export const apigetRouteSuccess = ( response ) => ({
    type: GETROUTESUCCESS,
    payload: response
});
