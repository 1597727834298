import React from "react";
// reactstrap components
import {connect} from "react-redux";
import {
    SET_ALERT_CREATE,
    SET_ALERT_DANGER,
    SET_ALERT_LOADING,
    SET_ALERT_SUCCESS, SET_ALERT_SUCCESS_QS,
    setAlert,
} from "../../../services/Redux/actions/ui";
import ReactBSAlert from "react-bootstrap-sweetalert";
import PacmanLoader from 'react-spinners/PulseLoader';
import {Button, CardBody, Col, FormGroup, Input, InputGroup, InputGroupAddon, Row} from "reactstrap";
import {createCustomer} from "../../../services/Redux/actions/createAction";

class SweetAlerts extends React.Component {
    state = {
        companyName: null,
        emailAddress: null,
        firstName: null,
        lastName: null,
        address: null,
        city: null,
        zip: null,
        telephone: null,
        isReseller: false,
        password: null
    };

    onConfirm = (e) =>{
        e.preventDefault();
        this.props.store.dispatch(createCustomer(this.state))
    }

    generatePassword = () =>{
        const generator = require('generate-password');
        const password = generator.generate({
            length: 12,
            numbers: true,
        });
        //this.state.password = password;
        this.setState({password:password})
    }

    render() {
        switch (this.props.AlertType){
            case SET_ALERT_LOADING: {
                return (
                    <ReactBSAlert
                        info
                        style={{ display: "flex"}}
                        title="Speichervorgang"
                        onConfirm={() => this.hideAlert()}
                        showConfirm={false}
                        show={this.props.isShownAlert}
                    >
                        <PacmanLoader/>
                    </ReactBSAlert>
                );
                break;
            }
            case SET_ALERT_SUCCESS: {
                return (
                    <ReactBSAlert
                        success
                        style={{ display: "block"}}
                        title="Yuhuu!"
                        onConfirm={() => this.props.store.dispatch(setAlert(false,SET_ALERT_SUCCESS))}
                        onCancel={() => this.props.store.dispatch(setAlert(false,SET_ALERT_SUCCESS))}
                        confirmBtnBsStyle="success"
                        confirmBtnText="Super"
                        btnSize=""
                        timeout={2000}
                        show={this.props.isShownAlert}
                    >
                        Erfolgreich gespeichert
                    </ReactBSAlert>
                );
                break;
            }
            case SET_ALERT_DANGER: {
                return (
                    <ReactBSAlert
                        danger
                        style={{ display: "block"}}
                        title="Schade!"
                        onConfirm={() => this.props.store.dispatch(setAlert(false,SET_ALERT_DANGER))}
                        onCancel={() => this.props.store.dispatch(setAlert(false,SET_ALERT_DANGER))}
                        confirmBtnBsStyle="danger"
                        confirmBtnText="Schließen"
                        btnSize=""
                        show={this.props.isShownAlert}
                    >
                        Es ist ein Fehler aufgetreten
                    </ReactBSAlert>
                );
                break;
            }
            case SET_ALERT_CREATE: {
                return (
                    <ReactBSAlert
                        title={"Account anlegen"}
                        style={{display:"block",width:"75%"}}
                        onConfirm={()=>{}}
                        confirmBtnText={"Benutzer anlegen"}
                        showConfirm={false}
                        onCancel={()=>this.props.store.dispatch(setAlert(false,SET_ALERT_CREATE))}
                        type={'controlled'}
                        show={this.props.isShownAlert}
                    >
                        <form onSubmit={this.onConfirm}>
                            <CardBody>
                            <h6 className="heading-small text-muted mb-4">
                                Reseller Informationen
                            </h6>
                            <div className="pl-lg-1">
                                <Row>
                                    <Col lg="6">
                                        <FormGroup>
                                            <label
                                                className="form-control-label"
                                                htmlFor="input-first-name"
                                            >
                                                Firma
                                            </label>
                                            <Input
                                                //defaultValue={RouteData.reseller_customers_firstname}
                                                id="input-first-name"
                                                name={"inputfirstname"}
                                                placeholder="Firma"
                                                onChange={(e)=>this.setState({companyName: e.target.value})}
                                                type="text"
                                                required={true}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                        <FormGroup>
                                            <label
                                                className="form-control-label"
                                                htmlFor="input-email"
                                            >
                                                Email addresse
                                            </label>
                                            <Input
                                                //defaultValue={RouteData.reseller_customers_email}
                                                id="input-email"
                                                name={"inputemail"}
                                                placeholder="info@ixdoo.com"
                                                onChange={(e)=>this.setState({emailAddress: e.target.value})}
                                                type="email"
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="6">
                                        <FormGroup>
                                            <label
                                                className="form-control-label"
                                                htmlFor="input-first-name"
                                            >
                                                Vorname
                                            </label>
                                            <Input
                                                //defaultValue={RouteData.reseller_customers_firstname}
                                                id="input-first-name"
                                                name={"inputfirstname"}
                                                placeholder="Vorname"
                                                onChange={(e)=>this.setState({firstName: e.target.value})}
                                                type="text"
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col lg="6">
                                        <FormGroup>
                                            <label
                                                className="form-control-label"
                                                htmlFor="input-last-name"
                                            >
                                                Nachname
                                            </label>
                                            <Input
                                                //defaultValue={RouteData.reseller_customers_name}
                                                id="input-last-name"
                                                name={"inputlastname"}
                                                placeholder="Nachname"
                                                onChange={(e)=>this.setState({lastName: e.target.value})}
                                                type="text"
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </div>
                            <hr className="my-4" />
                            <h6 className="heading-small text-muted mb-4">
                                Adress Informationen
                            </h6>
                            <div className="pl-lg-4">
                                <Row>
                                    <Col md="3">
                                        <FormGroup>
                                            <label
                                                className="form-control-label"
                                                htmlFor="input-address"
                                            >
                                                Addresse
                                            </label>
                                            <Input
                                                //defaultValue={RouteData.reseller_customers_address}
                                                id="input-address"
                                                name={"inputaddress"}
                                                placeholder="Musterstr.1"
                                                onChange={(e)=>this.setState({address: e.target.value})}
                                                type="text"
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col lg="3">
                                        <FormGroup>
                                            <label
                                                className="form-control-label"
                                                htmlFor="input-city"
                                            >
                                                Stadt
                                            </label>
                                            <Input
                                                //defaultValue={RouteData.reseller_customers_city}
                                                id="input-city"
                                                name={"inputcity"}
                                                placeholder="Musterstadt"
                                                onChange={(e)=>this.setState({city: e.target.value})}
                                                type="text"
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col lg="3">
                                        <FormGroup>
                                            <label
                                                className="form-control-label"
                                                htmlFor="input-country"
                                            >
                                                PLZ
                                            </label>
                                            <Input
                                                //defaultValue={RouteData.reseller_customers_zip}
                                                id="input-postal-code"
                                                placeholder="00000"
                                                name={"inputzip"}
                                                onChange={(e)=>this.setState({zip: e.target.value})}
                                                type="number"
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col lg="3">
                                        <FormGroup>
                                            <label
                                                className="form-control-label"
                                                htmlFor="input-country"
                                            >
                                                Telefon
                                            </label>
                                            <Input
                                                //defaultValue={RouteData.reseller_customers_zip}
                                                id="input-phoneNumber"
                                                placeholder="0177777777"
                                                name={"inputphone"}
                                                onChange={(e)=>this.setState({telephone: e.target.value})}
                                                type="number"
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg="8">
                                        <FormGroup>
                                            <label className="form-control-label">Passwort</label>
                                            <InputGroup className="mb-3">
                                                <Input
                                                    placeholder="##musterpasswort##"
                                                    defaultValue={this.state.password}
                                                    type="text"
                                                    name={"inputaddress"}
                                                    onChange={(e)=>this.setState({password: e.target.value})}
                                                    required={true}
                                                />
                                                <InputGroupAddon addonType="append">
                                                    <Button color="warning" type={"button"} onClick={this.generatePassword}>generieren</Button>
                                                </InputGroupAddon>
                                            </InputGroup>
                                        </FormGroup>
                                    </Col>
                                    <Col lg="4">
                                        <FormGroup>
                                            <label className="form-control-label">Als Reseller Registrieren</label>
                                            <label className="custom-toggle">
                                                <input type="checkbox" onChange={(e)=>this.setState({isReseller: e.target.checked}) }/>
                                                <span
                                                    className="custom-toggle-slider rounded-circle"
                                                    data-label-off="Nein"
                                                    data-label-on="Ja"
                                                />
                                            </label>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </div>
                        </CardBody>
                            <Button color="primary" type={"submit"}>Benutzer anlegen</Button>
                        </form>
                    </ReactBSAlert>
                );
                break;
            };
            case SET_ALERT_SUCCESS_QS: {
                return (
                    <ReactBSAlert
                        success
                        style={{ display: "block"}}
                        title="Yuhuu!"
                        onConfirm={() => this.props.store.dispatch(setAlert(false,SET_ALERT_SUCCESS))}
                        onCancel={() => this.props.store.dispatch(setAlert(false,SET_ALERT_SUCCESS))}
                        confirmBtnBsStyle="success"
                        confirmBtnText="Super"
                        btnSize=""
                        show={true}
                    >
                        Dein neuer <b>Kunde/Reseller</b> wurde angelegt!<br/>
                        Innerhalb von <b>5 Minuten</b> wird seine Software aktiviert.
                        <hr className="my-4" />
                        <h6 className="heading-section mb-4">
                            Zugangsdaten:
                        </h6>
                        <b>URL:</b> https://{this.props.AlertMessage.companyName}.quicksteps.ch/<br/>
                        <b>Benutzername:</b> {this.props.AlertMessage.companyName}<br/>
                        <b>Passwort:</b> {this.props.AlertMessage.password}<br/>
                    </ReactBSAlert>
                );
                break;
            }

        }
        return null;
    }
}

export default connect(({ isShownAlert,AlertType,AlertMessage}) => ({ isShownAlert,AlertType,AlertMessage}))(SweetAlerts);
