export const LOGIN = "LOGIN";
export const RouteData = "RouteData";
export const LOGOUT = "LOGOUT";

export const login = user => {
    return {
        type: LOGIN,
        payload: user
    };
};

export const logout = () => {
    return {
        type: LOGOUT
    };
};

export const getRouteData = (user) => {
    return {
        type: RouteData,
        payload: user
    };
};
