/*!

=========================================================
* Argon Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useContext} from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardImg,
  CardTitle,
  FormGroup,
  Form,
  Input,
  ListGroupItem,
  ListGroup,
  Progress,
  Container,
  Row,
  Col,
} from "reactstrap";
// core components
import ProfileHeader from "components/Headers/ProfileHeader.js";
import {ReactReduxContext} from "react-redux";
import {saveInfo, saveInfoData} from "../../../services/Redux/actions/saveAction";

function Profile() {
  const RouteData = JSON.parse(sessionStorage.getItem('data')).response;
  let countCustomer = 0;
  let countReseller = 0;

  const { store } = useContext(ReactReduxContext);


  RouteData.customer.map((data)=>{
    if(data.account.reseller_customers_is_reseller){
      countReseller++;
    }else{
      countCustomer++;
    }
  })

  const saveDataFunction = (e) => {
    e.preventDefault();
    store.dispatch(saveInfoData(
        {
          email:e.target.elements.inputemail.value,
          firstname:e.target.elements.inputfirstname.value,
          lastname:e.target.elements.inputlastname.value,
          address:e.target.elements.inputaddress.value,
          city:e.target.elements.inputcity.value,
          zip:e.target.elements.inputzip.value,
        })
    )
  }

  return (
    <>
      <ProfileHeader />
      <Container className="mt--6" fluid>
        <Row>
          <Col className="order-xl-2" xl="4">
            <Card className="card-profile">
              <CardImg
                alt="..."
                src={require("assets/img/theme/img-1-1000x600.jpg").default}
                top
              />
              <Row className="justify-content-center">
                <Col className="order-lg-2" lg="3">
                  <div className="card-profile-image">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        className="rounded-circle"
                        src={require("assets/img/theme/team-4.jpg").default}
                      />
                    </a>
                  </div>
                </Col>
              </Row>
              <CardHeader className="text-center border-0 pt-8 pt-md-4 pb-0 pb-md-4">
                <div className="d-flex justify-content-between">
                  <Button
                      className="mr-4"
                      color="info"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                      size="sm"
                  >
                    Notification
                  </Button>
                  <Button
                    className="float-right"
                    color="default"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                    size="sm"
                  >
                    Message
                  </Button>
                </div>
              </CardHeader>
              <CardBody className="pt-0">
                <Row>
                  <div className="col">
                    <div className="card-profile-stats d-flex justify-content-center">
                      <div>
                        <span className="heading">{countCustomer}</span>
                        <span className="description">Kunden</span>
                      </div>
                      <div>
                        <span className="heading">{countReseller}</span>
                        <span className="description">Reseller</span>
                      </div>
                      <div>
                        <span className="heading">{countCustomer+countReseller}</span>
                        <span className="description">Gesamt</span>
                      </div>
                    </div>
                  </div>
                </Row>
                <div className="text-center">
                  <h5 className="h3">
                    {RouteData.reseller_customers_firstname} {RouteData.reseller_customers_name}
                  </h5>
                  <div>
                    <i className="ni education_hat mr-2" />
                    {RouteData.account.reseller_accounts_reseller_code}
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col className="order-xl-1" xl="8">
            <Row>
              <Col lg="6">
                <Card className="bg-gradient-success border-0">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle
                          className="text-uppercase text-muted mb-0 text-white"
                          tag="h5"
                        >
                          Total traffic
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0 text-white">
                          350,897
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-white text-dark rounded-circle shadow">
                          <i className="ni ni-active-40" />
                        </div>
                      </Col>
                    </Row>
                    <p className="mt-3 mb-0 text-sm">
                      <span className="text-white mr-2">
                        <i className="fa fa-arrow-up" />
                        3.48%
                      </span>
                      <span className="text-nowrap text-light">
                        Since last month
                      </span>
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="6">
                <Card className="bg-gradient-danger border-0" tag="h5">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle className="text-uppercase text-muted mb-0 text-white">
                          Performance
                        </CardTitle>
                        <span className="h2 font-weight-bold mb-0 text-white">
                          49,65%
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-white text-dark rounded-circle shadow">
                          <i className="ni ni-spaceship" />
                        </div>
                      </Col>
                    </Row>
                    <p className="mt-3 mb-0 text-sm">
                      <span className="text-white mr-2">
                        <i className="fa fa-arrow-up" />
                        3.48%
                      </span>
                      <span className="text-nowrap text-light">
                        Since last month
                      </span>
                    </p>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Form onSubmit={saveDataFunction}>
            <Card>
              <CardHeader>
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Profil bearbeiten</h3>
                  </Col>
                  <Col className="text-right" xs="4">
                    <Button
                      color="primary"
                      type="submit"
                      size="sm"
                    >
                      Speichern
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                  <h6 className="heading-small text-muted mb-4">
                    Reseller Informationen
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Kennung
                          </label>
                          <Input
                            defaultValue={RouteData.reseller_customers_company}
                            id="input-username"
                            placeholder="Username"
                            type="text"
                            disabled={true}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Email address
                          </label>
                          <Input
                              defaultValue={RouteData.reseller_customers_email}
                            id="input-email"
                              name={"inputemail"}
                              placeholder="jesse@example.com"
                            type="email"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            First name
                          </label>
                          <Input
                            defaultValue={RouteData.reseller_customers_firstname}
                            id="input-first-name"
                            name={"inputfirstname"}
                            placeholder="First name"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-last-name"
                          >
                            Last name
                          </label>
                          <Input
                            defaultValue={RouteData.reseller_customers_name}
                            id="input-last-name"
                            name={"inputlastname"}
                            placeholder="Last name"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <hr className="my-4" />

                  <h6 className="heading-small text-muted mb-4">
                    Contact information
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col md="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-address"
                          >
                            Address
                          </label>
                          <Input
                            defaultValue={RouteData.reseller_customers_address}
                            id="input-address"
                            name={"inputaddress"}
                            placeholder="Home Address"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-city"
                          >
                            City
                          </label>
                          <Input
                            defaultValue={RouteData.reseller_customers_city}
                            id="input-city"
                            name={"inputcity"}
                            placeholder="City"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-country"
                          >
                            Country
                          </label>
                          <Input
                            defaultValue="Deutschland"
                            id="input-country"
                            placeholder="Country"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-country"
                          >
                            Postal code
                          </label>
                          <Input
                              defaultValue={RouteData.reseller_customers_zip}
                            id="input-postal-code"
                            placeholder="Postal code"
                              name={"inputzip"}
                            type="number"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
              </CardBody>
            </Card>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Profile;
