import React, {useContext} from "react";
import {connect, ReactReduxContext} from "react-redux";
import { Redirect, Route } from "react-router";
import {getRouteData} from "./actions/auth";
import {GETROUTESUCCESS} from "./actions/api";

const AuthRoute = props => {
    //console.log(props)
    const { isAuthUser, type } = props;
    if (type === "guest" && isAuthUser) return <Redirect to="/app" />;
    else if (type === "private" && !isAuthUser) return <Redirect to="/login" />;

    if(type === "guest") {
        return <Route
            {...props} />;
    }

    /*props.routes.map((prop, key) => {
        if(prop.views){
            prop.views.map((views) => {
                console.log(views.id)
            });
        } else {
            console.log(prop.id)
        }
    });*/

    //console.log(props.store.getState().isAuthUser)

    return <Route
        onEnter={props.store.dispatch(
            getRouteData(props.location.pathname)
        )}
        {...props} />
};

const mapStateToProps = ({ isAuthUser }) => ({
    isAuthUser
});

export default connect(mapStateToProps)(AuthRoute);
