export const SET_LOADER = "SET_LOADER";
export const SET_ALERT_SUCCESS = "SET_MODAL_SUCCESS";
export const SET_ALERT_SUCCESS_QS = "SET_MODAL_SUCCESS_QS";
export const SET_ALERT_LOADING = "SET_ALERT_LOADING";
export const SET_ALERT_DANGER = "SET_ALERT_DANGER";
export const SET_ALERT_CREATE = "SET_ALERT_CREATE";

export const setLoader = (state) => ({
    type: SET_LOADER,
    payload: state
});

export const setAlert = (state,type,msg=null) => ({
    type: type,
    payload: state,
    msg: msg
});
