import axios from "axios";
import {
    API_REQUEST,
    GETDASHBOARDDATA,
    apiError,
    apiSuccess,
    apigetRouteSuccess,
    SETINFORMATIONDATA, API_CREATECUSTOMER, API_CREATECUSTOMER_QS, apiCreateCustomerQS
} from "./actions/api";
import {
    SET_ALERT_CREATE,
    SET_ALERT_DANGER,
    SET_ALERT_LOADING,
    SET_ALERT_SUCCESS, SET_ALERT_SUCCESS_QS,
    setAlert,
    setLoader
} from "./actions/ui";

export const apiMiddleware = ({ dispatch }) => next => action => {
    next(action);

    if (action.type === API_REQUEST)
    {
        dispatch(setLoader(true));
        const { url, method, data } = action.meta;
        axios({
            method,
            url,
            data,
        })
            .then(({ data }) =>{
                dispatch(apiSuccess({ response: data }))
                dispatch(setLoader(false))
            })
            .catch(error => {
                dispatch(apiError({ error: error.response.data }));
            });
    }
    else if (action.type === GETDASHBOARDDATA)
    {
        dispatch(setLoader(true));
        const { url, method} = action.meta;
        axios({
            method,
            url,
            headers: {'Authorization': 'Bearer ' + localStorage.getItem("token")}
        }).then(({ data }) =>{
            dispatch(apigetRouteSuccess({ response: data }))
            dispatch(setLoader(false))
        })
            .catch(error => {
                dispatch(apiError({ error: error.response.data }));
            });
    }
    else if (action.type === SETINFORMATIONDATA)
    {
        dispatch(setAlert(true,SET_ALERT_LOADING));
        const {url, method,data} = action.meta;
        axios({
            method,
            url,
            data,
            headers: {'Authorization': 'Bearer ' + localStorage.getItem("token")}
        }).then(() => {
            dispatch(setAlert(false,SET_ALERT_LOADING));
            dispatch(setAlert(true,SET_ALERT_SUCCESS));
        }).catch(error => {
            dispatch(setAlert(true,SET_ALERT_DANGER));
        });
    }
    else if (action.type === API_CREATECUSTOMER)
    {
        dispatch(setAlert(true,SET_ALERT_LOADING));
        const {url, method,data} = action.meta;
        axios({
            method,
            url,
            data,
            headers: {'Authorization': 'Bearer ' + localStorage.getItem("token")}
        }).then(() => {
            dispatch(apiCreateCustomerQS({method,data}))
        }).catch(error => {
            dispatch(setAlert(true,SET_ALERT_DANGER));
        });
    }
    else if (action.type === API_CREATECUSTOMER_QS)
    {
        const {method,data} = action.meta;
        const url = action.metaurl;
        axios({
            method,
            url,
            data,
        }).then((response) => {
            dispatch(setAlert(false,SET_ALERT_LOADING));
            dispatch(setAlert(true,SET_ALERT_SUCCESS_QS,data));
        }).catch(error => {
            dispatch(setAlert(true,SET_ALERT_DANGER));
        });
    }
};
