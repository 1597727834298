import {apiRequest, apiGetDashboardData, apiSaveInformation, apiCreateCustomer} from "./actions/api";
import {LOGIN, RouteData} from "./actions/auth";
import {saveInfo} from "./actions/saveAction";
import {SET_ALERT_CREATE, setAlert} from "./actions/ui";
import {CREATE_CUSTOMER} from "./actions/createAction";

const SERVER_URL = 'https://resellerapi.quicksteps.ch/api';

export const appMiddleware = () => next => action => {
    next(action);
    switch (action.type) {
        case LOGIN: {
            next(
                apiRequest({
                    url: SERVER_URL+'/login',
                    method: "POST",
                    data: action.payload
                })
            );
            break;
        }
        case RouteData: {
            switch (action.payload){
                case "/app/info": {
                    next(
                        apiGetDashboardData({
                            url: SERVER_URL+'/get/resellerData/'+localStorage.getItem("UserId"),
                            method: "GET",
                        })
                    );
                    break;
                }
                case "/app/dashboard": {
                    next(
                        apiGetDashboardData({
                            url: SERVER_URL+'/get/resellerData/'+localStorage.getItem("UserId"),
                            method: "GET",
                        })
                    );
                    break;
                }
                case "/app/reseller": {
                    next(
                        apiGetDashboardData({
                            url: SERVER_URL+'/get/resellerData/'+localStorage.getItem("UserId"),
                            method: "GET",
                        })
                    );
                    break;
                }
                case "/app/customer": {
                    next(
                        apiGetDashboardData({
                            url: SERVER_URL+'/get/resellerData/'+localStorage.getItem("UserId"),
                            method: "GET",
                        })
                    );
                    break;
                }
            }
            break;
        }
        case saveInfo: {
            switch (action.toAPI){
                case true: {
                    next(
                        apiSaveInformation({
                            url: SERVER_URL+'/update/resellerData/'+localStorage.getItem("UserId"),
                            method: "PUT",
                            data: action.payload
                        })
                    );
                    break;
                }
                case false: {
                    next(
                        setAlert(true,SET_ALERT_CREATE)
                    );
                    break;
                }
            }
            break;
        }
        case CREATE_CUSTOMER: {
            next(
                apiCreateCustomer({
                    url: SERVER_URL+'/create/resellerData/'+localStorage.getItem("UserId"),
                    method: "POST",
                    data: action.payload,
                })
            );
            break;
        }
        default:
            break;
    }
};
