/*!

=========================================================
* Argon Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Alternative from "views/pages/dashboards/Alternative.js";
import Dashboard from "views/pages/dashboards/Dashboard.js";
import Login from "views/pages/examples/Login";
import Profile from "./views/pages/examples/Profile";
import Tables from "./views/pages/tables/Tables";
import ReactBSTables from "./views/pages/tables/ReactBSTables";
import Sortable from "./views/pages/tables/Sortable";
import ResellerTable from "./views/pages/tables/ResellerTable";
import CustomerTable from "./views/pages/tables/CustomerTable";

const routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    icon: "ni ni-shop text-primary",
    component: Dashboard,
    layout: "/app",
    visible: true,
    id: 1
  },
  {
    path: "/info",
    name: "Deine Daten",
    icon: "fas fa-users text-primary",
    component: Profile,
    layout: "/app",
    visible: true,
    id: 2
  },
  {
    collapse: true,
    name: "Kundenübersicht",
    icon: "fas fa-table text-orange",
    state: "examplesCollapse",
    visible: true,
    views: [
      {
        path: "/reseller",
        name: "Reseller",
        //icon: "fas fa-table text-primary",
        miniName: "R",
        component: ResellerTable,
        layout: "/app",
        visible: true,
        id: 3
      },
      {
        path: "/customer",
        name: "Kunden",
        miniName: "K",
        component: CustomerTable,
        layout: "/app",
        visible: true,
        id: 4
      },
    ],
  },

    //Auth Routes
  {
    path: "/login",
    name: "Login",
    miniName: "L",
    component: Login,
    layout: "/auth",
    visible: false,
    id: 5
  },
    /*
  {
    collapse: true,
    name: "Components",
    icon: "ni ni-ui-04 text-info",
    state: "componentsCollapse",
    views: [
      {
        path: "/buttons",
        name: "Buttons",
        miniName: "B",
        component: Buttons,
        layout: "/admin",
      },
      {
        path: "/cards",
        name: "Cards",
        miniName: "C",
        component: Cards,
        layout: "/admin",
      },
      {
        path: "/grid",
        name: "Grid",
        miniName: "G",
        component: Grid,
        layout: "/admin",
      },
      {
        path: "/notifications",
        name: "Notifications",
        miniName: "N",
        component: Notifications,
        layout: "/admin",
      },
      {
        path: "/icons",
        name: "Icons",
        miniName: "I",
        component: Icons,
        layout: "/admin",
      },
      {
        path: "/typography",
        name: "Typography",
        miniName: "T",
        component: Typography,
        layout: "/admin",
      },
      {
        collapse: true,
        name: "Multi Level",
        miniName: "M",
        state: "multiCollapse",
        views: [
          {
            path: "#pablo",
            name: "Third level menu",
            component: () => {},
            layout: "/",
          },
          {
            path: "#pablo",
            name: "Just another link",
            component: () => {},
            layout: "/",
          },
          {
            path: "#pablo",
            name: "One last link",
            component: () => {},
            layout: "/",
          },
        ],
      },
    ],
  },
     */
];

export default routes;
