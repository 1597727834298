import {LOGOUT} from "./actions/auth";
import {API_ERROR, API_SUCCESS, GETROUTESUCCESS} from "./actions/api";
import {
    SET_LOADER,
    SET_ALERT_LOADING,
    SET_ALERT_SUCCESS,
    SET_ALERT_DANGER,
    SET_ALERT_CREATE, SET_ALERT_SUCCESS_QS
} from "./actions/ui";

export default (
    state = {
        isAuthUser: !!localStorage.getItem("token"),
        token: localStorage.getItem("token") || {},
        UserId: JSON.parse(localStorage.getItem("UserId")) || {},
        isLoading: false,
        isShownModal: false,
        isShownAlert: false,
        error: null,
        AlertType: null,
        AlertMessage: null,
        data: sessionStorage.getItem("UserId") || {},
    },
    action
) => {
    switch (action.type) {
        case API_SUCCESS:
            localStorage.setItem("token", action.payload.token);
            localStorage.setItem("UserId", JSON.stringify(action.payload.data.id));
            return { ...state, isAuthUser: true, token: action.payload.token };
       case GETROUTESUCCESS:
           sessionStorage.setItem("data", JSON.stringify(action.payload));
           return { ...state, isAuthUser: true, token: action, data: action };
        case API_ERROR:
            return { ...state, error: action.payload };
        case SET_LOADER:
            return { ...state, isLoading: action.payload };
        case SET_ALERT_LOADING:
            return { ...state, isShownAlert: action.payload, AlertType: action.type};
        case SET_ALERT_SUCCESS:
            return { ...state, isShownAlert: action.payload, AlertType: action.type};
        case SET_ALERT_SUCCESS_QS:
            return { ...state, isShownAlert: action.payload, AlertType: action.type, AlertMessage: action.msg};
        case SET_ALERT_DANGER:
            return { ...state, isShownAlert: action.payload, AlertType: action.type};
        case SET_ALERT_CREATE:
            return { ...state, isShownAlert: action.payload, AlertType: action.type};
        case LOGOUT:
            localStorage.removeItem("token");
            return { ...state, isAuthUser: false, token: {} };
        default:
            return state;
    }
};
