import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import AuthLayout from "./layouts/Auth";
import AdminLayout from '../src/layouts/Admin';
import React from "react";
import {connect, Provider} from "react-redux";
import {applyMiddleware, compose, createStore} from "redux";
import {appMiddleware} from "./services/Redux/appMiddleware";
import reducer from "./services/Redux/reducer";
import {apiMiddleware} from "./services/Redux/apiMiddleware";
import AuthRoute from "./services/Redux/HOC";
import LoadingScreen from "./views/pages/components/LoadingScreen";
import routes from "./routes";
import Modal from "./views/pages/components/Modal";
import SweetAlert from "./views/pages/components/SweetAlert";
import {store} from "./services/Redux/store";

/*const composeEnhancers =
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        }) : compose;

const enhancer = composeEnhancers(
    applyMiddleware(
        appMiddleware,
        apiMiddleware,
    ),
);
const store = createStore(reducer, enhancer);*/
/*const createStoreWithMiddleware = applyMiddleware(
    appMiddleware,
    apiMiddleware,
)(createStore());

const store = createStoreWithMiddleware(reducer);*/

function App() {
    return (
        <Provider store={store}>
            <SweetAlert store={store}/>
            <LoadingScreen/>
            <BrowserRouter>
                <Switch>
                    <AuthRoute type={"private"} store={store} routes={routes} path="/app" render={(props) => <AdminLayout {...props} />} />
                    <AuthRoute type={"guest"} path="/login" render={(props) => <AuthLayout {...props} />} />
                    {/*
                    <Route path="/rtl" render={(props) => <RTLLayout {...props} />} />
                    <Route path="/" render={(props) => <IndexView {...props} />} />
                    */}
                    <Redirect from="*" to="/login" />
                </Switch>
            </BrowserRouter>
        </Provider>
    );
}

export default App;
